import axios from "axios";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import { server } from "./constants";
import NewsDetails from "./pages/NewsDetails";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import LoginPopup from "./layout/LoginPopup";
import Draft from "./pages/Draft";
function App() {
  const [loginPopup, setLoginPopup] = useState(false);
  axios.defaults.baseURL = server;
  useEffect(() => {
    const controller = new AbortController();
    if (window.location.origin.includes("gondiakhabar.in")) {
      window.open("https://gondiakhabar.com", "_self");
    }
    if (
      !localStorage.getItem("ghost_id") &&
      !localStorage.getItem("user_uuid")
    ) {
      newEntity(controller);
    }
    const timer = setInterval(() => {
      let time = +(localStorage.getItem("timer") || 0);
      console.log(time);
      if (time >= 60) {
        if (!localStorage.getItem("user_uuid")) {
          setLoginPopup(true);
        }
        clearInterval(timer);
      } else {
        localStorage.setItem("timer", time + 25);
      }
    }, 25000);

    return () => {
      controller.abort();
      clearInterval(timer);
    };
  }, []);
  const newEntity = async (controller) => {
    let ghost_id = uuid();
    const response = await axios({
      method: "post",
      url: "/users/newEntry",
      signal: controller.signal,
      data: { ghost_id },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.success) {
      localStorage.setItem("ghost_id", ghost_id);
    }
  };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path={`/app`}
            element={<Home setLoginPopup={setLoginPopup} />}
          />
          <Route
            path={`/app/:news_id`}
            element={<Home setLoginPopup={setLoginPopup} />}
          />
           <Route
            path={`/draft/:news_id`}
            element={<Draft />}
          />
          <Route path={`/news_details/:news_id`} element={<NewsDetails />} />
          <Route path="*" element={<Navigate replace to={"/app"} />} />
        </Routes>
      </Router>
      {loginPopup ? <LoginPopup setLoginPopup={setLoginPopup} /> : ""}
    </div>
  );
}

export default App;
