import React, { useCallback, useEffect, useState } from "react";
import AppBar from "../layout/AppBar";
import ContentWrapper from "../layout/ContentWrapper";
import { styled } from "@mui/material/styles";

import axios from "axios";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ArrowDropDown } from "@mui/icons-material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, padding: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Layout = ({ setLoginPopup }) => {
  const [item_categories, setItemCategories] = useState([]);
  const [activecategories, setActiveCategories] = useState({});

  const GetOrganizationData = async () => {
    const response = await axios({
      method: "get",
      url: "Category/getCategory/",

      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.success) {
      setItemCategories([
        { index: 0, category_uuid: 0, category_title: "All" },
        ...(response.data.result
          .map((a, i) => ({ ...a, index: i + 1 }))
          .sort((a, b) => +a.sort_order - +b.sort_order) || []),
      ]);
    }
  };
  useEffect(() => {
    if (item_categories.length) setActiveCategories(item_categories[0]);
  }, [item_categories]);

  useEffect(() => {
    GetOrganizationData();
  }, []);

  return (
    <>
      <div className="flex" style={{ width: "100vw", height: "100vh" }}>
        <div className="layout">
          {/* <AppBar /> */}
          <Box
            sx={{
              width: "100%",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              height: "cal(100vh - 56px)",
            }}
            className="flex"
          >
            {item_categories.map((item, i) => (
              <TabPanel
                value={activecategories.index}
                index={item?.index}
                key={item?.category_uuid}
                className="flex"
              >
                <ContentWrapper
                  activecategories={activecategories}
                  setLoginPopup={setLoginPopup}
                />
              </TabPanel>
            ))}
          </Box>
        </div>
      </div>
    </>
  );
};

export default Layout;
