import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

const LoginPopup = ({ setLoginPopup }) => {
  const [credentials, setCredentials] = useState({ number: "" });
  const [isLoading, setIsLoading] = useState();
  const [focused, setfocused] = useState();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [otpBox, setOtpBox] = useState(false);
  useEffect(() => {
    document.getElementById("user_number")?.focus();
  }, []);
  console.log(credentials);
  const onChange = (e) =>
    setCredentials((prev) => ({
      ...prev,
      number: e.target.value.length <= 10 ? e.target.value : prev.number,
    }));
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    setCredentials((prev) => ({
      ...prev,
      otp: [...otp.map((d, idx) => (idx === index ? element.value : d))]
        .toString()
        .replace(/,/g, ""),
    }));
    //Focus next input

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const callLoginHandler = async (otpBox) => {
    if (!otpBox) {
      if (credentials.number.length !== 10) {
        return;
      }
      let data = credentials;
      const response = await axios({
        method: "post",
        url: "users/sendOtp/",
        data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.success) {
        setOtpBox(true);
        document.getElementById("idOtp")?.focus();
      }
    } else {
      let data = {
        ...credentials,
        user_uuid: localStorage.getItem("ghost_id"),
      };
      const response = await axios({
        method: "post",
        url: "users/varifyUser/",
        data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.success) {
        localStorage.setItem("user_uuid", response.data.result.user_uuid);
        localStorage.removeItem("ghost_id");
        setLoginPopup(false);
      }
    }
  };
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(255,255,255,0.6)",
        display: "flex",
        justifyContent:focused? "flex-start":"center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "90vw",
          maxWidth: "400px",
          backgroundColor: "#fff",
          height: "max-content",
          borderRadius: "20px",
          padding: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          boxShadow: "2px 5px 10px #000",
        }}
      >
        {otpBox ? (
          <>
            <h1 style={{ textAlign: "left", margin: "0" }}>OTP Verification</h1>
            <div className="form" style={{ height: "150px", width: "80%" }}>
              <div className="input-container" style={{ position: "relative" }}>
                <h6 style={{ opacity: "0.3" }}>Enter the OTP you recived to</h6>
                <h6>+91 {credentials.number.slice(0, 6)}XXXX</h6>
                {/* <input
                  //   type="number"
                  //   className="form-input"
                  name="number"
                  //   id="user_number"
                  type="text"
                  maxLength="6"
                  value={credentials.otp}
                  onChange={(e) =>
                    setCredentials((prev) => ({ ...prev, otp: e.target.value }))
                  }
                  autoComplete="off"
                  variant="standard"
                  style={{
                    // width: "100%",
                    paddingLeft: "12px",
                    letterSpacing: "21px",
                    border: 0,
                    backgroundImage:
                      "linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%)",
                    backgroundPosition: "bottom",
                    backgroundSize: "32px 1px",
                    backgroundRepeat: "repeat-x",
                    backgroundPositionX: "28px",
                    width: "200px",
                    outline: "none",
                    overflowX:"hidden"
                  }}
                  required
                /> */}
                <div style={{ display: "flex" }}>
                  {otp.map((data, index) => {
                    return (
                      <input
                        className="otp-field"
                        id={index === 0 ? "idOtp" : ""}
                        type="number"
                        name="otp"
                        onFocusCapture={e=>setfocused(true)
                        }
                        maxLength="1"
                        key={index}
                        onKeyDown={(e) => {
                          if (
                            e.key === "Backspace" &&
                            e.target.previousSibling &&
                            !e.target.value
                          ) {
                            e.target.previousSibling.focus();
                          } else if (
                            e.key === "Backspace" &&
                            e.target.previousSibling
                          ) {
                            setOtp([
                              ...otp.map((d, idx) => (idx === index ? "" : d)),
                            ]);
                            setCredentials((prev) => ({
                              ...prev,
                              otp: [
                                ...otp.map((d, idx) =>
                                  idx === index ? "" : d
                                ),
                              ]
                                .toString()
                                .replace(/,/g, ""),
                            }));
                          }
                        }}
                        value={data}
                        onChange={(e) => handleChange(e.target, index)}
                        onFocus={(e) => e.target.select()}
                        style={
                          index === 3
                            ? { marginLeft: "10px" }
                            : index === 0
                            ? { marginLeft: "10px" }
                            : {}
                        }
                      />
                    );
                  })}
                </div>
              </div>
              <div
                className="flex"
                style={{ color: "#0077b6", justifyContent: "flex-start" }}
                onClick={() => callLoginHandler()}
              >
                Resend OTP <ArrowRight />
              </div>
            </div>
          </>
        ) : (
          <>
            <h1 style={{ textAlign: "left", margin: "0" }}>Login</h1>
            <div className="form" style={{ height: "150px", width: "80%" }}>
              <div className="input-container" style={{ position: "relative" }}>
                <label
                  htmlFor="user_number"
                  className="form-label"
                  style={{ fontSize: "18px" }}
                >
                  Phone Number
                </label>
                <TextField
                onFocus={e=>setfocused(true)}
                  type="number"
                  className="form-input"
                  name="number"
                  id="user_number"
                  value={credentials.number}
                  onChange={onChange}
                  autoComplete="off"
                  variant="standard"
                  style={{ width: "100%", paddingLeft: "50px" }}
                  required
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: "1px",
                    fontSize: "18px",
                    borderBottom: "1px solid #000",
                    backgroundColor: "var(--gray)",
                  }}
                >
                  +91 -
                </div>
              </div>
              <h6 style={{ opacity: "0.3" }}>
                A 6 digit OTP will be sent via SMS to verify your mobile number
              </h6>
            </div>
          </>
        )}
      </div>

      <div style={{ marginTop: "10px" }}>
        {!isLoading ? (
          <button
            className="submit-btn flex"
            onClick={(e) => {
              e.preventDefault();
              callLoginHandler(otpBox);
            }}
          >
            Continue <ArrowRight />
          </button>
        ) : (
          <button className="submit-btn" id="loading-screen">
            <svg viewBox="0 0 100 100">
              <path
                d="M10 50A40 40 0 0 0 90 50A40 44.8 0 0 1 10 50"
                fill="currentColor"
                stroke="none"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  dur="1s"
                  repeatCount="indefinite"
                  keyTimes="0;1"
                  values="0 50 51;360 50 51"
                ></animateTransform>
              </path>
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default LoginPopup;
