import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDrag } from '@use-gesture/react';
import { motion } from "framer-motion";
import { toBlob } from "html-to-image";
import { useSwipeable } from "react-swipeable";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import {
  ChatBubbleOutline,
  Favorite,
  FavoriteBorder,
  ShareOutlined,
  YouTube,
} from "@mui/icons-material";
import { Helmet } from "react-helmet";
import axios from "axios";
import { FastAverageColor } from "fast-average-color";

function formatAMPM(date) {
  var weekdays = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let today = new Date();
  var r = weekdays[date.getDay()];
  var Month = monthNames[date.getMonth()];
  var dateNumber = date.getDate();
  var year = date.getFullYear();
  var strTime =
    r === weekdays[today.getDay()] &&
    Month === monthNames[today.getMonth()] &&
    year === today.getFullYear()
      ? "Today"
      : r + " " + dateNumber + " " + Month + " " + year;
  // " " +
  // hours +
  // ":" +
  // minutes +
  // " " +
  // ampm;
  return strTime;
}
const TypesOfOutlets = ({ item, pos, LikesCount, setLoginPopup }) => {
  const [position, setPosition] = useState(0);

  const navigate = useNavigate();
  const [width, setWidth] = useState(null);
  // console.log(item);
  const parentElement = useCallback((node) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);
  const title = useMemo(() => {
    if (pos) {
      return (
        <Helmet>
          <title>{item.title}</title>
          <meta name="description" content={item.brief} />
        </Helmet>
      );
    } else {
      return "";
    }
  }, [pos]);
  const pages = useMemo(
    () =>
      item?.img_url
        ?.sort((a, b) => a.sort_order - b.sort_order)
        .map((a, i) => ({ index: i, src: a })) || [],

    [item?.img_url]
  );
  useEffect(() => {
    setPosition(pages[0]);
  }, [pages]);

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      setPosition((prev) =>
        prev?.index === pages.length - 1
          ? prev
          : pages.find((a) => a?.index === position?.index + 1)
      );
      console.log("swiped left", eventData);
    },
    onSwipedRight: (eventData) => {
      setPosition((prev) =>
        prev?.index === 0
          ? prev
          : pages.find((a) => a?.index === prev?.index - 1)
      );
      console.log("swiped right", eventData);
    },
    delta: 60, // Reduced delta to 30 for more responsive swipe detection
    preventScrollOnSwipe: true,
    trackTouch: true,
    trackMouse: true,
    rotationAngle: 0,
    swipeDuration: Infinity,
    touchEventOptions: { passive: false }, // Changed to false for better touch handling
  });
  const bind = useDrag(({ swipe: [swipeX] }) => {
    if (swipeX === -1) {
      console.log("Swiped left");
      setPosition((prev) => 
        prev?.index === pages.length - 1
          ? prev
          : pages.find((a) => a?.index === prev?.index + 1)
      );
    } else if (swipeX === 1) {
      console.log("Swiped right");
      setPosition((prev) =>
        prev?.index === 0
          ? prev
          : pages.find((a) => a?.index === prev?.index - 1)
      );
    }
  }, { axis: 'x', filterTaps: true });

  const handleShare = async (id) => {
    const newFile = await toBlob(document.getElementById(id));
    const data = {
      files: [
        new File([newFile], "image.png", {
          type: newFile.type,
        }),
      ],
      title: "Image",
      text: "image",
    };

    try {
      if (!navigator.canShare(data)) {
        console.error("Can't share");
      }
      await navigator.share(data);
    } catch (err) {
      console.error(err);
    }
  };
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    const fac = new FastAverageColor();
    fac.getColorAsync(item.img_data)
      .then(color => {

        setIsDark(color.isDark);
      })
      .catch(err => {
        console.error(err);
      });

    return () => fac.destroy();
  }, [item.img_data]);
  return item.img_data ? (
    <div
      {...bind()}
      style={{
        position: "relative",
        width: "100%",
        height: "fit-content",
        borderRadius: "30px",
        // padding: "10px",
        // marginTop: "10px",
      }}
      ref={parentElement}
    >
      <img
        src={item.img_data }
        style={{
          objectFit: "contain",
          height: "100vh",
        }}
        alt="abc"
      />
      {console.log(isDark)}
      <div
      style={{
        position: "absolute",
        bottom: "10%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        textAlign: "center",
        width: "100%",
        fontSize: "2rem",
        color: isDark ? "#fff" : "#000",
      }}
      >{item.title}</div>
    </div>
  ) : (
    <div
      {...handlers}
      style={{
        position: "relative",
        width: "100%",
        height: "fit-content",
        borderRadius: "30px",
        // padding: "10px",
        // marginTop: "10px",
      }}
      ref={parentElement}
    >
      {title}
      <div
        style={{
          position: "relative",
          objectFit: "contain",
          width: "100%",
          height: (width * 9) / 16,
        }}
      >
        {item.yt_video_id ? (
          <motion.div
            className="container_main"
            id={item.news_id}
            initial={{ rotate: 1 }}
            animate={{
              rotate: 0,
              left: `${0}vw`,
            }}
            transition={{
              type: "tween",
              bounceStifafness: 260,
              bounceDamping: 20,
            }}
            style={{
              objectFit: "contain",
              width: "100%",
              height: (width * 9) / 16,
            }}
          >
            <div style={{ position: "relative" }}>
              {/* <img
                src={`http://img.youtube.com/vi/${item.yt_video_id}/0.jpg`}
                style={{
                  objectFit: "fill",
                  width: "100%",
                  height: (width * 9) / 16,
                }}
                alt="abc"
              /> */}

              <iframe
                src={`https://www.youtube.com/embed/${
                  item?.yt_video_id
                }?rel=0&autoplay=${pos ? 1 : 0}`}
                style={{
                  objectFit: "fill",
                  width: "100%",
                  height: (width * 9) / 16,
                }}
                title={item?.title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              {/* <div onClick={() => setVideoPopup(item)} className="YoutubeBtn">
                <YouTube style={{ fontSize: "100px" }} />
              </div> */}
            </div>
          </motion.div>
        ) : (
          pages.map((page, i) => (
            <motion.div
              key={i}
              id={item.news_id}
              className="container_main"
              initial={{ rotate: 1 }}
              animate={{
                rotate: 0,
                left: `${(page?.index - (position?.index || 0)) * 100}vw`,
                top: "0",
              }}
              transition={{
                type: "tween",
                bounceStifafness: 260,
                bounceDamping: 20,
              }}
              style={{
                objectFit: "contain",
                width: "100%",
                height: (width * 9) / 16,
                // position: "relative",
              }}
            >
              <div
                className="navigation_btn"
                style={{
                  left: "15px",
                  opacity: position?.index === 0 ? 0 : 1,
                }}
                onClick={() => {
                  setPosition((prev) =>
                    prev?.index === 0
                      ? prev
                      : pages.find((a) => a?.index === prev?.index - 1)
                  );
                }}
              >
                <ArrowBackIosIcon fontSize="30" />
              </div>
              <div
                className="navigation_btn"
                style={{
                  right: "10px",
                  opacity: position?.index === pages.length - 1 ? 0 : 1,
                }}
                onClick={() => {
                  setPosition((prev) =>
                    prev?.index === pages.length - 1
                      ? prev
                      : pages.find((a) => a?.index === position?.index + 1)
                  );
                }}
              >
                <ArrowForwardIosIcon fontSize="30" />
              </div>
              <div
                style={{
                  backgroundImage: `url(${page.src})`,
                  filter: "blur(8px)",
                  height: "100%",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
              <img
                src={page?.src}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: (width * 9) / 16,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                alt="abc"
              />
            </motion.div>
          ))
        )}
      </div>
      <div
        style={{
          position: "absolute",
          top: ((width - 30) * 9) / 16,
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          gap: "5px",
        }}
      >
        {pages.map((page, i) => (
          <div
            onClick={() => {
              setPosition(page);
            }}
            style={{
              height: "10px",
              width: "10px",
              borderRadius: "50%",
              backgroundColor: position?.index === i ? "black" : "white",
            }}
          />
        ))}
      </div>

      <div
        className="flex"
        style={{
          zIndex: 99999999,
          // padding: "0 5px",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "90%",
            textAlign: "left",
            color: "var(--black)",
            fontWeight: "300",
            opacity: "0.5",
            fontSize: "10px",
            fontFamily: "'Poppins', monospace",
          }}
        >
          {formatAMPM(new Date(item.created_at))}
        </div>
        <div
          style={{
            width: "90%",
            textAlign: "left",
            color: "var(--black)",
            fontWeight: "600",
            fontSize: "20px",
            fontFamily: "'Poppins', monospace",
            lineHeight: "1.2",
          }}
        >
          <span
            style={{
              margin: "0 5px",
              color: item?.likes?.find(
                (a) => a === localStorage.getItem("user_uuid")
              )
                ? "red"
                : "#000",
            }}
            onClick={() => {
              if (localStorage.getItem("user_uuid")) {
                LikesCount(item.news_id);
              } else {
                setLoginPopup(true);
              }
            }}
          >
            {item?.likes?.find(
              (a) => a === localStorage.getItem("user_uuid")
            ) ? (
              <Favorite />
            ) : (
              <FavoriteBorder />
            )}
          </span>
          <span style={{ margin: "0 5px" }}>
            <ChatBubbleOutline />
          </span>
          <span
            style={{ margin: "0 5px" }}
            onClick={() => {
              handleShare(item.news_id);
            }}
          >
            <ShareOutlined />
          </span>
        </div>
        <div
          style={{
            width: "90%",
            textAlign: "left",
            color: "var(--black)",
            fontWeight: "300",
            opacity: "0.5",
            fontSize: "10px",
            fontFamily: "'Poppins', monospace",
          }}
        >
          {item?.views?.length || 0} views
        </div>

        <div
          style={{
            width: "90%",
            textAlign: "left",
            color: "var(--black)",
            fontWeight: "600",
            fontSize: "20px",
            fontFamily: "'Poppins', monospace",
            lineHeight: "1.2",
            margin: "10px 0",
          }}
        >
          {item.title || ""}
        </div>
        {item?.brief ? (
          <p
            style={{
              width: "90%",
              textAlign: "left",
              wordWrap: "break-word",

              color: "var(--black)",
              fontWeight: "400",
              fontSize: "15px",
              opacity: "0.7",
              fontFamily: "'Poppins', monospace",
              lineHeight: "1.2",
            }}
          >
            {item?.brief.slice(0, 200) || ""}
            {item?.desciption.trim() !== '<p></p>' ? (
              <>
                ...
                <u
                  style={{ color: "var(--secendary)", cursor: "pointer" }}
                  onClick={() => navigate("/news_details/" + item.news_id)}
                >
                  more
                </u>
              </>
            ) : (
              ""
            )}
          </p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TypesOfOutlets;
