import React, { useState, useEffect, useMemo } from "react";
import { motion } from "framer-motion";
import { useSwipeable } from "react-swipeable";
import { Box, CircularProgress } from "@mui/material";
import axios from "axios";
import TypesOfOutlets from "../pages/TypesOfOutlets";
import { useNavigate, useParams } from "react-router-dom";
import { Close } from "@mui/icons-material";

const ContentWrapper = ({ activecategories, setLoginPopup }) => {
  const [position, setPosition] = useState();
  const [videoPopup, setVideoPopup] = useState("");
  const [firstTime, setFirstTime] = useState();
  const [Items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const viewCount = async (controller, position, pages) => {
    const response = await axios({
      method: "post",
      url: "/news_published/upadteView",
      signal: controller.signal,
      data: {
        ghost_id: localStorage.getItem("ghost_id"),
        user_uuid: localStorage.getItem("user_uuid"),
        news_id: pages.find((a) => a.index === position)?.item.news_id,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.success) {
    }
  };

  const GetOrganizationData = async (category_uuid) => {
    const response = await axios({
      method: "get",
      url: "news_published/getnews_published",

      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.success) {
      setItems(response.data.result);
      setLoading(false);
    }
  };
  useEffect(() => {
    GetOrganizationData(activecategories?.category_uuid);
  }, [activecategories?.category_uuid]);

  const pages = useMemo(
    () =>
      Items?.sort((a, b) => +b.published_at - +a.published_at)?.map(
        (item, i) => ({
          index: i,
          name: item?.item_title,
          item,
        })
      ),
    [Items]
  );
  useEffect(() => {
    const controller = new AbortController();
    let count = 0;
    const timer = setInterval(() => {
      if (count) {
        viewCount(controller, position, pages);
        clearInterval(timer);
      } else {
        count = count + 1;
      }
    }, 5000);

    return () => {
      controller.abort();
      clearInterval(timer);
    };
  }, [position, pages]);
  useEffect(() => {
    if (pages.length && !firstTime) {
      if (params.news_id) {
        setPosition(
          pages.find((a) => a.item.news_id === params.news_id)?.index
        );
      } else {
        setPosition(pages[0]?.index);

        navigate("/app/" + pages[0].item.news_id);
      }
      setFirstTime(true);
    }
  }, [pages, params.news_id]);

  const handlers = useSwipeable({
    onSwipedUp: () => {
      setPosition((prev = 0) => {
        let item = prev === pages.length - 1 ? prev : prev + 1;
        navigate("/app/" + pages.find((a) => a.index === item)?.item.news_id);
        console.log(prev, pages.length);
        return item;
      });
      console.log("swipeup");
    },
    onSwipedDown: () => {
      setPosition((prev) => {
        let item = prev === 0 ? prev : prev - 1;
        navigate("/app/" + pages.find((a) => a.index === item)?.item.news_id);
        return item;
      });
      console.log("swipedown");
    },
    delta: 60, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: true, // prevents scroll during swipe (*See Details*)
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0, // set a rotation angle
    swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: false },
  });
  const LikesCount = async (news_id) => {
    setItems((prev) =>
      prev.map((a) =>
        a.news_id === news_id
          ? {
              ...a,
              likes: a.likes.find(
                (b) => b === localStorage.getItem("user_uuid")
              )?a.likes.filter(
                (b) => b !== localStorage.getItem("user_uuid")
                ):[...a.likes, localStorage.getItem("user_uuid")]
            }
          : a
      )
    );
    const response = await axios({
      method: "post",
      url: "/news_published/upadteLikes",

      data: {
        user_uuid: localStorage.getItem("user_uuid"),
        news_id,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.success) {
      GetOrganizationData();
    }
  };
  return (
    <>
      {loading ? (
        <Box>
          <div
            className="flex"
            style={{
              width: "100vw",
              maxWidth: "480px",
              height: "fit-content",
              maxHeight: "calc(100vh - 74px)",
              overflow: "hidden",
            }}
          >
            <CircularProgress />
          </div>
        </Box>
      ) : (
        <div
          style={{
            width: "100vw",
            maxWidth: "480px",
            height: "fit-content",
            maxHeight: "calc(100vh - 74px)",
            overflow: "hidden",
          }}
          className="flex"
          {...handlers}
        >
          <div
            style={{
              position: "relative",
              objectFit: "contain",
              width: "100%",
              height: "calc(100vh - 74px)",
            }}
            className="flex"
          >
            {pages.map((page, i) => (
              <motion.div
                key={i}
                className="container_anime flex"
                initial={{ rotate: 1 }}
                animate={{
                  rotate: 0,
                  top: `${(page.index - (position || 0)) * 100}vh`,
                }}
                transition={{
                  type: "tween",
                  bounceStifafness: 260,
                  bounceDamping: 20,
                }}
                style={{
                  height: "max-content",
                  maxHeight: "calc(100vh - 74px)",
                  width: "100%",
                  overflowY: "scroll",
                }}
              >
                <TypesOfOutlets
                  item={page?.item}
                  value={(position + 1 || 0) + "/" + (Items?.length || 0)}
                  verticalhandlers={handlers}
                  setVideoPopup={setVideoPopup}
                  pos={position === page.index}
                  LikesCount={LikesCount}
                  setLoginPopup={setLoginPopup}
                />
              </motion.div>
            ))}
          </div>
        </div>
      )}

      {videoPopup.yt_video_id ? (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.7)",
            zIndex: "9999999",
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            left: 0,
          }}
        >
          <div style={{ position: "relative" }}>
            <iframe
              src={`https://www.youtube.com/embed/${videoPopup?.yt_video_id}?rel=0&autoplay=1`}
              style={{ objectFit: "fill", width: "100vw", height: "200px" }}
              title={videoPopup?.title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <div
              style={{
                position: "absolute",
                color: "red",
                cursor: "pointer",
                top: "-60px",
                right: "1%",
                backgroundColor: "#fff",
                borderRadius: "50%",
                display: "flex",
                padding: "3px",
              }}
              onClick={() => setVideoPopup("")}
            >
              <Close style={{ fontSize: "40px" }} />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ContentWrapper;
