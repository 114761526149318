import React, { useEffect, useState } from "react";
import AppBar from "../layout/AppBar";
import { motion } from "framer-motion";
import DOMPurify from "dompurify";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ArrowBack } from "@mui/icons-material";
import DynamicContent from "../components/DynamicContent";
function formatAMPM(date) {
  var weekdays = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var r = weekdays[date.getDay()];
  var Month = monthNames[date.getMonth()];
  var dateNumber = date.getDate();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime =
    r +
    " " +
    dateNumber +
    " " +
    Month +
    " " +
    date.getFullYear() +
    " " +
    hours +
    ":" +
    minutes +
    " " +
    ampm;
  return strTime;
}
const NewsDetails = () => {
  const [item, setItem] = useState({});
  const params = useParams();
  const navigate = useNavigate();
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }
  const getNew = async (news_id) => {
    const response = await axios({
      method: "get",
      url: "/news_published/getnews_published/" + news_id,

      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.success) {
      setItem(response.data.result);
    }
  };
  useEffect(() => {
    if (params.news_id) {
      getNew(params.news_id);
    }
  }, [params.news_id]);
  useEffect(() => {
    if (document?.getElementById("myLink"))
      document
        ?.getElementById("myLink")
        ?.addEventListener("click", function (event) {
          event.preventDefault(); // Prevents the default action of following the link
          window.open(this.getAttribute("href"), "_blank"); // Opens the link in a new tab
        });
  });
  var regex =
    /<iframe[^>]+src=["'](?:https?:\/\/(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/)([a-zA-Z0-9_-]+))["'][^>]*>/g;

  return (
    <>
      <div className="flex" style={{ width: "100vw", height: "100vh" }}>
        <div className="layout">
          <AppBar />
          <div
            style={{
              color: "var(--white)",
              position: "fixed",
              top: "10px",
              left: 0,
            }}
            onClick={() => navigate(-1)}
          >
            <ArrowBack style={{ fontSize: "35px" }} />
          </div>
          <div
            style={{
              width: "100vw",
              maxWidth: "480px",
              height: "fit-content",
              maxHeight: "calc(100vh - 74px)",
              overflow: "hidden",
            }}
            className="flex"
          >
            <div
              style={{
                position: "relative",
                objectFit: "contain",
                width: "100%",
                height: "calc(100vh - 74px)",
              }}
              className="flex"
            >
              <motion.div
                className="container_anime flex"
                initial={{ rotate: 1 }}
                animate={{
                  rotate: 0,
                  top: `${0}vh`,
                }}
                transition={{
                  type: "tween",
                  bounceStifafness: 260,
                  bounceDamping: 20,
                }}
                style={{
                  height: "max-content",
                  maxHeight: "calc(100vh - 74px)",
                  width: "100%",
                  overflowY: "scroll",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "90%",
                    height: "fit-content",
                    borderRadius: "30px",
                    // padding: "10px",
                    margin: "20px 10px",
                  }}
                >
                  <h1
                    style={{
                      width: "100%",
                      textAlign: "left",
                      color: "var(--black)",
                      fontWeight: "600",
                      fontSize: "24px",
                      fontFamily: "'Poppins', monospace",
                      lineHeight: "1.1",
                    }}
                  >
                    {item?.title || ""}
                  </h1>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "left",
                      color: "var(--black)",
                      fontWeight: "300",
                      opacity: "0.5",
                      fontSize: "10px",
                      fontFamily: "'Poppins', monospace",
                    }}
                  >
                    {item?.created_at
                      ? formatAMPM(new Date(item?.created_at || ""))
                      : ""}
                  </div>
                  <div style={{ maxHeight: "70vh", overflow: "scroll" }}>
                  <DynamicContent content={item.desciption} />
                    {/* <div
                      className="preview"
                      dangerouslySetInnerHTML={createMarkup(
                        (item?.desciption || "")
                          .replace(/<p><\/p>/g, "<br/>")
                          .replace(/\n/g, "<br/>")

                          .replace(
                            /<ul>/g,
                            '<div style={{ display: "flex", flexDirection: "column" }}>'
                          )

                          .replace(/<\/ul>/g, "</div>")
                          .replace(/target="_blank"/g, 'id="myLink" ')

                          .replace(/<img/g, '<img style="width:100%" ')
                          .replace(regex, function (match, videoId) {
                            return (
                              '<iframe width="100%" height="315" src="https://www.youtube.com/embed/' +
                              videoId +
                              '?si=7rYbHumS3MhWgATk" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>'
                            );
                          })
                      )}
                    ></div> */}
                    
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsDetails;
