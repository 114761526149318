import * as React from "react";
import { AppBar, Container, Toolbar } from "@mui/material";
import { HiBars3 } from "react-icons/hi2";
import { ShoppingCart, WhatsApp } from "@mui/icons-material";
import { Box } from "@mui/material";
import { motion } from "framer-motion";

import { BsTelephoneFill } from "react-icons/bs";

const ResponsiveAppBar = () => {
  const [dropdown, setDropDown] = React.useState(false);
  return (
    <AppBar position="static" style={{ background: "var(--main)" }}>
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          style={{
            minWidth: "300px",
            flex: "1",
            justifyContent: "space-between",
          }}
        >
          {/* <div
            style={{ width: "30px", height: "20px", objectFit: "contain" }}
            className="flex"
          >
            <HiBars3 style={{ fontSize: "30px", fontWeight: "bold" }} />
          </div> */}

          <h2 style={{ width: "100%",textAlign:"center" }}>Gondia Khabar</h2>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
