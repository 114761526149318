import React, { useEffect } from 'react';

const InstagramEmbed = ({ url }) => {
  useEffect(() => {
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    } else {
      const script = document.createElement('script');
      script.async = true;
      script.defer = true;
      script.src = 'https://www.instagram.com/embed.js';
      document.body.appendChild(script);
    }
  }, [url]);

  return (
    <blockquote
      className="instagram-media"
      data-instgrm-permalink={url}
      data-instgrm-version="14"
      style={{ margin: '1px', border: '0', width: '100%', maxWidth: '540px' }}
    >
      <a href={url} target="_blank" rel="noopener noreferrer">
        Instagram Post
      </a>
    </blockquote>
  );
};

export default InstagramEmbed;
