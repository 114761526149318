import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import TypesOfOutlets from "./TypesOfOutlets";
import { Close } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, padding: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Draft = () => {
  const [position, setPosition] = useState();
  const [videoPopup, setVideoPopup] = useState("");
  const [loading, setLoading] = useState(true);
  const params = useParams();

  const GetOrganizationData = async () => {
    const response = await axios({
      method: "get",
      url: "news_published/getDraft/" + params.news_id,

      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.success) {
      let data = response.data.result;
      setPosition({
        index: 0,
        name: data.title,
        item: data,
      });
      setLoading(false);
    }
  };

  const handlers = useSwipeable({
    onSwipedUp: () => {},
    onSwipedDown: () => {},
    delta: 60, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: true, // prevents scroll during swipe (*See Details*)
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0, // set a rotation angle
    swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true },
  });
  useEffect(() => {
    GetOrganizationData();
  }, []);

  return (
    <>
      <div className="flex" style={{ width: "100vw", height: "100vh" }}>
        <div className="layout">
          {/* <AppBar /> */}
          <Box
            sx={{
              width: "100%",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              height: "cal(100vh - 56px)",
            }}
            className="flex"
          >
            {loading ? (
              <Box>
                <div
                  className="flex"
                  style={{
                    width: "100vw",
                    maxWidth: "480px",
                    height: "fit-content",
                    maxHeight: "calc(100vh - 74px)",
                    overflow: "hidden",
                  }}
                >
                  <CircularProgress />
                </div>
              </Box>
            ) : (
              <div
                style={{
                  width: "100vw",
                  maxWidth: "480px",
                  height: "fit-content",
                  maxHeight: "calc(100vh - 74px)",
                  overflow: "hidden",
                }}
                className="flex"
                {...handlers}
              >
                <div
                  style={{
                    position: "relative",
                    objectFit: "contain",
                    width: "100%",
                    height: "calc(100vh - 74px)",
                  }}
                  className="flex"
                >
                  <motion.div
                    key={0}
                    className="container_anime flex"
                    initial={{ rotate: 1 }}
                    animate={{
                      rotate: 0,
                      top: 0,
                    }}
                    transition={{
                      type: "tween",
                      bounceStifafness: 260,
                      bounceDamping: 20,
                    }}
                    style={{
                      height: "max-content",
                      maxHeight: "calc(100vh - 74px)",
                      width: "100%",
                      overflowY: "scroll",
                    }}
                  >
                    <TypesOfOutlets
                      item={position?.item}
                      value={1}
                      verticalhandlers={handlers}
                      setVideoPopup={setVideoPopup}
                      pos={true}
                    />
                  </motion.div>
                </div>
              </div>
            )}

            {videoPopup.yt_video_id ? (
              <div
                style={{
                  position: "fixed",
                  backgroundColor: "rgba(0,0,0,0.7)",
                  zIndex: "9999999",
                  width: "100vw",
                  height: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  top: 0,
                  left: 0,
                }}
              >
                <div style={{ position: "relative" }}>
                  <iframe
                    src={`https://www.youtube.com/embed/${videoPopup?.yt_video_id}?rel=0&autoplay=1`}
                    style={{
                      objectFit: "fill",
                      width: "100vw",
                      height: "200px",
                    }}
                    title={videoPopup?.title}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                  <div
                    style={{
                      position: "absolute",
                      color: "red",
                      cursor: "pointer",
                      top: "-60px",
                      right: "1%",
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                      display: "flex",
                      padding: "3px",
                    }}
                    onClick={() => setVideoPopup("")}
                  >
                    <Close style={{ fontSize: "40px" }} />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </Box>
        </div>
      </div>
    </>
  );
};

export default Draft;
